<div class="icat-login_wrapper">
    <div class="inner_block">
        <div class="container">
            <div class="loginbggrey">
                <div class="login-bg">
                    <div class="bg-txt">
                        <img src="/assets/img/moglix-logo.jpg" width="200" alt="Moglix Logo" />
                        <h3>Catalog Information System</h3>
                    </div>
                </div>
                <div class="login-white">

                    <div class="google-login">
                    
                        <button type="submit" class="ggl-log ms-log" style="cursor: pointer;" (click)="microsoftSignIn()">LOGIN with Microsoft</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>