import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare let gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsServiceService {

  constructor(private router: Router) {
    this.init();
  }

  trackPageView(page: string) {
    gtag('set', 'page', page);
    gtag('send', 'pageview');
  }


  private init() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-14WKMJ0D8V', { page_path: event.urlAfterRedirects });
      }
    });
  }

  trackEvent(eventName: string, eventCategory: string, eventLabel: string) {
    
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
     });

     gtag('set', 'userId', localStorage.getItem('email')); 
    
}
}
