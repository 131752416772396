/**
 * Created by km arti on 18/07/23.
 */


export const urls = {

    //////////////localhost///////////////////////////////////////////////


    ////////////////Dev UI/////////////////////////////////////////////////

    // catalogUserLogin: '/catalog/user/login',
    // prod_supp: "https://catalogapi-dev.moglilabs.com",
    // search:'https://catalogsearch-dev.moglilabs.com/',
    // platformdatprod:"https://platformdata-dev.moglilabs.com/",
    // searchBrand:'https://catalogueapi.moglix.com/',
    // catalog : 'https://catalogapi-dev.moglilabs.com/',
    // s3Link:"https://s3.ap-south-1.amazonaws.com/imgqa.moglix.com/",
    // categoryL1:'https://platformdata-dev.moglilabs.com/cassandraFeed/cis/category/list',
    // msnDetails:'https://catalogapi-dev.moglilabs.com/mapper/fetchMsn/',
    // supplierDetails:'https://catalogsearch-dev.moglilabs.com/search/product/supplier',
    // supplierTransaction:'https://catalogsearch-dev.moglilabs.com/search/product/supplier/transactions',
    // productDetails: "https://platformdata-dev.moglilabs.com/cassandraFeed/fetchMsn/",
    // eventTracker:"https://searchpltdata-dev.moglilabs.com/searchDataload/event/tracker/add",
    // businessDetails: "https://platformdata-dev.moglilabs.com/cassandraFeed/businessUnitsDetails"

    ////////////////PROD UI/////////////////////////////////////////////////


    catalogUserLogin: '/catalog/user/login',
    prod_supp: "https://catalogapi.moglix.com",
    search: 'https://catalogsearch.moglix.com/',
    platformdatprod: "https://platformdataprod.moglix.com/",
    searchBrand: 'https://catalogsearch.moglix.com/',
    catalog: 'https://catalogapi.moglix.com/',
    s3Link: "https://img.moglimg.com/",
    categoryL1: 'https://platformdataprod.moglix.com/cassandraFeed/cis/category/list',
    msnDetails: 'https://catalogapi.moglix.com/mapper/fetchMsn/',
    supplierDetails: 'https://catalogsearch.moglix.com/search/product/supplier',
    supplierTransaction: 'https://catalogsearch.moglix.com/search/product/supplier/transactions',
    productDetails: "https://platformdataprod.moglix.com/cassandraFeed/fetchMsn/",
    eventTracker: "https://searchpltdataprodnew.moglix.com/searchDataload/event/tracker/add",
    businessDetails:"https://platformdataprod.moglix.com/cassandraFeed/businessUnitsDetails"
}
